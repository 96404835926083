import type { ComponentType } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useState, useEffect } from "react"

export function withCountUp1000speed1(Component): ComponentType {
    return (props) => {
        const [count, setCount] = useState(0)

        useEffect(() => {
            if (count < 1000) {
                const intervalId = setInterval(() => {
                    setCount((prevCount) => prevCount + 1)
                }, 1)
                return () => clearInterval(intervalId)
            }
        }, [count])

        return <Component {...props} text={`${count}`} />
    }
}

export function withCountUp50speed50(Component): ComponentType {
    return (props) => {
        const [count, setCount] = useState(0)

        useEffect(() => {
            if (count < 50) {
                const intervalId = setInterval(() => {
                    setCount((prevCount) => prevCount + 1)
                }, 50)
                return () => clearInterval(intervalId)
            }
        }, [count])

        return <Component {...props} text={`${count}`} />
    }
}

export function withCountUp10speed500(Component): ComponentType {
    return (props) => {
        const [count, setCount] = useState(0)

        useEffect(() => {
            if (count < 10) {
                const intervalId = setInterval(() => {
                    setCount((prevCount) => prevCount + 1)
                }, 500)
                return () => clearInterval(intervalId)
            }
        }, [count])

        return <Component {...props} text={`${count}`} />
    }
}

export function withCountUpOpacity(Component): ComponentType {
    return (props) => {
        const [count, setCount] = useState(0)

        useEffect(() => {
            if (count < 32) {
                const intervalId = setInterval(() => {
                    setCount((prevCount) => prevCount + 1)
                }, 50)
                return () => clearInterval(intervalId)
            }
        }, [count])

        const variants = {
            start: {
                opacity: 0,
            },
            end: {
                opacity: count / 32,
            },
        }

        return (
            <motion.div
                variants={variants}
                animate="end"
                style={{ display: "inline-block" }}
            >
                <Component {...props} text={`${count}`} />
            </motion.div>
        )
    }
}

export function withCountUpRotate(Component): ComponentType {
    return (props) => {
        const [count, setCount] = useState(0)

        useEffect(() => {
            if (count < 32) {
                const intervalId = setInterval(() => {
                    setCount((prevCount) => prevCount + 1)
                }, 50)
                return () => clearInterval(intervalId)
            }
        }, [count])

        const variants = {
            start: {
                rotate: 0,
            },
            end: {
                rotate: count * 20,
            },
        }

        return (
            <motion.div
                variants={variants}
                animate="end"
                transition={{
                    type: "spring",
                    stiffness: 150,
                    damping: 10,
                }}
                style={{ display: "inline-block" }}
            >
                <Component {...props} text={`${count}`} />
            </motion.div>
        )
    }
}

export function withCountUpTranslation(Component): ComponentType {
    return (props) => {
        const [count, setCount] = useState(0)

        const variants = {
            start: { x: -20 },
            end: { x: 20 },
        }

        useEffect(() => {
            if (count < 32) {
                const intervalId = setInterval(() => {
                    setCount((prevCount) => prevCount + 1)
                }, 50)
                return () => clearInterval(intervalId)
            }
        }, [count])

        return (
            <motion.div variants={variants} animate="end" initial="start">
                <Component {...props} text={`${count}`} />
            </motion.div>
        )
    }
}

export function withCountUpColor(Component): ComponentType {
    return (props) => {
        const [count, setCount] = useState(0)

        const variants = {
            start: { backgroundColor: "#212121" },
            end: { backgroundColor: "#f9a825" },
        }

        useEffect(() => {
            if (count < 32) {
                const intervalId = setInterval(() => {
                    setCount((prevCount) => prevCount + 1)
                }, 50)
                return () => clearInterval(intervalId)
            }
        }, [count])

        return (
            <motion.div variants={variants} animate="end" initial="start">
                <Component {...props} text={`${count}`} />
            </motion.div>
        )
    }
}

export function withCountUpBlurTranslate(Component): ComponentType {
    return (props) => {
        const [count, setCount] = useState(0)

        const variants = {
            start: {
                filter: "blur(5px)",
                x: 50,
                opacity: 0,
            },
            end: {
                filter: "blur(0px)",
                x: 0,
                opacity: 1,
            },
        }

        useEffect(() => {
            if (count < 32) {
                const intervalId = setInterval(() => {
                    setCount((prevCount) => prevCount + 1)
                }, 50)
                return () => clearInterval(intervalId)
            }
        }, [count])

        return (
            <motion.div
                variants={variants}
                animate="end"
                initial="start"
                style={{
                    display: "inline-block",
                    ...props.style,
                }}
            >
                <Component {...props} text={`${count}`} />
            </motion.div>
        )
    }
}
